.placeholder {
  font-size: 0.9rem;
  color: var(--grey);
}

.create-post {
  display: flex;
  flex-direction: column;
  width: 500px;
  border: 1px solid var(--primaryLight);
  border-radius: 10px;
  margin: 1rem 0 1rem 0;
}

.create-post .header {
  background-color: var(--primaryLight);
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.create-post .body .textarea {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: text;
}

.create-post .body .textarea img {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin-right: 1rem;
  object-fit: cover;
}

.actions, .actions-create {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin: 0.5rem 1rem 1rem 0;
  cursor: pointer;
  font-size: 0.8rem;
}

.actions-create {
  margin: 0.5rem 1rem 1rem 4rem;
}

.action{
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: var(--primaryLight);
}

.action img {
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
}

.action:hover {
  background-color: var(--grey);
}


.selected-images-bar{
  margin: 0.5rem 0 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}


.selected-images-bar-create {
  margin: 0 0 0 4rem;
  width: 435px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}