form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

input:not([type='checkbox']) {
  font-size: 0.9rem;
}

@media screen and (min-width: var(--break-md)) {
  input:not([type='checkbox']) {
    width: 20rem;
  }
}

input {
  margin: 0.25rem 0;
  height: 20px;
  border-radius: 10px;
  background-color: var(--primaryLight);
  padding: 0.75rem 1rem;
  border: none;
  font-size: 16px;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
}

.top-bar-search {
  width: 50vw;
  margin-left: 10vw;
}

.top-bar input {
  width: 80%;
  background-color: var(--white);
  flex: 1;
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
}


form input[type="checkbox"] {
  width: 1rem;
  margin: 0.25rem;
}

form a {
  color: var(--accent);
  text-decoration: none;
}

label {
  font-weight: bold;
  width: 15vw;
  margin-right: 1rem;
  text-align: left;
  font-size: 0.9rem;
}

@media screen and (min-width: 768px) {
  label {
    width: 10vw;
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.form-row > input {
  width: 100%;
}

.form-row > .img-delete-wrapper{
  margin:0.25rem;
}

.form-row > .img-delete-wrapper > img{
  height: 225px;
  width: 225px;
  object-fit: cover;
  border-radius: 5px;
}

.form-button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

form input[type="checkbox"] {
  width: auto;
}

form input[type="date"]:before{
  color:var(--primaryDarkTransparent);
  content:attr(placeholder);
  padding-right: 2px;
}

input[type="date"].full:before {
  color:black;
  content:""!important;
}

form .google-places-autocomplete__input {
  width: 100%;
  box-shadow: none;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  height: 100%;
}

form .google-places-autocomplete {
  width: 100%;
  flex: 6;
}

@media screen and (min-width: 768px) {
  #edit-profile .google-places-autocomplete__input {
    width: 100%;
  }
}

.repetition-type-input {
  width: 100%;
}

.select-date-input, .repetition-type-input {
  margin: 0.25rem;
  height: 20px;
  border-radius: 10px;
  background-color: var(--primaryLight);
  padding: 0.75rem 1rem;
  border: none;
  box-shadow: none;
}

.modal-row > .react-datepicker-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.privacy-checkbox {
  border: none;
  border-radius: 0.5rem;
  background-color: var(--primaryLight);
  padding: 0.75rem;
  color: var(--primaryLight);
  cursor: pointer;
  width: 15px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
}

.privacy-toggle-wrapper {
  position: relative;
  border: none;
  border-radius: 0.5rem;
  color: var(--primaryLight);
  cursor: pointer;
  width: 6rem;
  min-width: 6rem;
  height: 2.75rem;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 0.25rem;
  transition: 1s all;
  flex: 1;
}

.privacy-toggle-wrapper:hover .tooltip {
  opacity: 1;
}

.public {
  background-color: var(--accent);
}

.secret {
  background-color: var(--primaryLight);
}

.privacy-toggle {
  position: absolute;
  left: 0;
  transform: translateX(0);
  height: 85%;
  width: 47.5%;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-left: 0.25rem;
  transition: 0.5s all;
}

.secret .privacy-toggle {
  margin-left: 0;
  margin-right: 0.25rem;
  transform: translateX(100%);
}

.privacy-toggle img {
  width: 70%;
  height: auto;
}

@media screen and (min-width: var(--break-md)) {
  .privacy-checkbox {
    border-radius: 0.5rem;
    padding: 0.75rem;
    width: 20px;
    height: 20px;
    margin-left: 0.5rem;
  }
}

.privacy-checkbox img {
  width: 1.5rem;
}

.img-delete-wrapper {
  position: relative;
  cursor: pointer;
}

.img-delete-wrapper:hover .img-delete{
  display: block;
}

.img-delete-wrapper .img-delete {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1rem;
  height: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 0.75rem;
  color: var(--primaryDark);
  cursor: pointer;
  display: none;
}

.img-delete img {
  width: 1rem;
  height: 1rem;
}

textarea.expanding {
  min-height: 3rem;
  max-height: 10rem;
}

textarea {
  height: 20px;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: var(--primaryLight);
  padding: 0.75rem 1rem;
  font-size: 16px;
  box-shadow: none;
  margin: 0.5rem 0;
}

.switch-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.25rem;
  margin: 2rem 0 1rem 0;
  border-bottom: 1px solid var(--primaryLight);
}

.switch-action{
  margin-right: 1.5rem;
  cursor: pointer;
}

.switch-action.active{
  color: var(--accent);
}

.error-message{
  max-width: 15rem;
  text-align: center;
  color: var(--error);
}

.success-message{
  max-width: 15rem;
  text-align: center;
  color: var(--accent);
  width: 100%;
  max-width: 100%;
}

.error-image {
  max-height: 400px;
  max-width: 400px;
  object-fit: cover;
  margin-top: 2rem;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.error-page > a {
  margin-top: 2rem;
}

.image-input {
  display: none;
}

.create-comment-field {
  flex: 1;
}

#sign-in-form > input {
  width: 15rem;
}

#additional-info .form-row {
  width: 40rem;
}

#additional-info .form-row > input:not([type='checkbox']) {
  width: 25rem;
}

#edit-profile .form-row {
  width: 100%;
}

#edit-profile label {
  width: 10rem;
  margin-right: 1rem;
}

#label-google-places {
  width: 6vw;
}

.birthdayandlocation {
  width: 14rem;
}

#edit-profile .google-places-autocomplete {
  width: auto;
}

#sign-up-options .error-message {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}


.reset-password-options, .reset-password-options .error-message {
  min-width: 400px;
  max-width: 100%;
  margin: 0 2rem 1rem 2rem;
}

.checkbox-margin-right{
  margin-right: 8px;
}


.google-places-autocomplete__suggestions-container{
  width: 34rem;
}

.google-places-autocomplete__suggestion {
  padding: 0.5rem;
  cursor: pointer;
  color: var(--accent);
}