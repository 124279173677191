.booking-plan-header {
  background-color: var(--primaryLight);
  border-bottom: 1px solid var(--grey);
  padding: 0.5rem 2rem;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.stable-facility {
  display: flex;
  align-items: center;
}

.stable-facility .language-select {
  margin-left: 1rem;
}

.booking-modal-info {
  margin: 0.25rem;
  height: 20px;
  border-radius: 10px;
  background-color: var(--primaryLight);
  padding: 0.75rem 1rem;
  border: none;
  font-size: 16px;
  box-shadow: none;
}

.booking-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-width: 400px;
}

.booking-modal-row-date {
  min-width: 300px;
}

.booking-modal-header {
  margin-left: 1rem;
}

.booking-plan {
  overflow: auto;
  width: 100vw;
}

.booking-plan-content {
  display: flex;
  justify-content: stretch;
}

.all-bookings {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: stretch;
  width: 100%;
}

.bookings-header {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.bookings-header > div:not(#time-slot-header-wrapper), .all-bookings > div {
  width: 100%;
}

.booking {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

.booking-slot-info {
  max-height: 100%;
  position: absolute;
  overflow: hidden;
  font-size: 0.8rem;
  padding: 0.35rem;
  text-align: left;
}

.booking-slot-info img, .booking-preview-info img {
  height: 1rem;
  margin-right: 0.2rem;
}

.booking-slot-info div:first-of-type {
  margin-bottom: 0.5rem;
  display: flex;
  font-size: 0.9rem;
  align-items: center;
}

.booking-primary{
  z-index:10;
}

.booking-preview {
  min-width: 300px;
  position: absolute;
  padding: 1rem;
  top: 0;
  background-color: var(--white) ;
  border: 1px solid var(--primaryLight);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .25);
}

.booking-preview-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.booking-preview-info{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: start;
  font-size: 0.8rem;
}

.booking-preview-info-grey{
  color: var(--grey);
}

.time-slot {
  background-color: var(--primaryLight);
  border: 1px solid var(--primaryLight);
  height: 86px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0.5rem;
}

.time-slot .hour {
  font-size: 1.2rem;
  margin-right: 0.15rem;
}

.time-slot .minute {
  font-size: 0.8rem;
  margin-right: 0.25rem;
}

.time-slot-header {
  background-color: var(--primaryLight);
  border: 1px solid var(--primaryLight);
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-slot, .time-slot-header {
  width: 5rem;
}

.slot-header {
  background-color: var(--primaryLight);
  border: 1px solid var(--primaryLight);
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-slots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: sticky;
  left: 0;
  z-index: 1000;
}

.free-booking-slot {
  border: 1px solid var(--primaryLight);
  height: 22px;
}

.free-booking-slot:hover {
  background-color: var(--grey);
}

.booking-slot-highlight {
  background-color: var(--grey);
}

.used-booking-slot {
  height: 24px;
  cursor: pointer;
}

.weekday-bubble {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: var(--primaryLight);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  background-color: var(--accent);
}

.current-time-line {
  width: 100%;
  border: solid 1px red;
  position: absolute;
}

.booking-selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.maxBookingDurationInfo {
  font-size: 0.9rem;
  text-align: left;
  color: var(--darkGrey);
}

.bookingDaysWarningMessage {
  color: var(--error);
}