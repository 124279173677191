@import "css/spacings.css";
@import "css/forms.css";
@import "css/grids.css";
@import "css/buttons.css";
@import "css/cards.css";
@import "css/user.css";
@import "css/posts.css";
@import "css/bookings.css";

@import "css/components.css";

:root {
  --primaryDark: rgba(47, 50, 35, 1);
  --primaryLight: rgba(244, 244, 244, 1);
  --accent: rgba(122, 193, 67, 1);
  --darkGrey: rgba(168, 168, 168, 1);
  --primaryDarkTransparent: rgba(47, 50, 35, 0.85);
  --primaryLightTransparent: rgba(244, 244, 244, 0.85);
  --grey: rgba(210, 210, 210, 1);
  --error: rgba(193, 67, 67, 1);
  --white: rgba(255, 255, 255, 1);
  --break-sm: 576px;
  --break-md: 768px;
  --break-lg: 992px;
  --break-xl: 1200px;
  --default-form-size: 300px;
  --content-margin: 20rem;
  --content-margin-mobile: 0rem;
  --header-padding: 23rem;
}

@media (max-width: 1440px) {
  :root {
    --content-margin: 2rem;
    --header-padding: 2rem;
  }
}

body, html {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
}

body {
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: 100vh;
  background-color: var(--white);
}

h1, h2, h3, h4, h5 {
  margin: 0;
  line-height: 100%;
  color: var(--primaryDark);
}

#root {
  min-width: 100%;
}

.fullPage {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1023px) {
  .corner-ribbon {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .corner-ribbon {
    width: 200px;
    background: #e43;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 30px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  /* Custom pdfStyles */
  .corner-ribbon.sticky {
    position: fixed;
  }

  .corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  }

  .corner-ribbon.blue {
    background: hotpink;
  }

  .corner-ribbon.top-right {
    top: 25px;
    right: -50px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

}

.full-col {
  width: 100%;
  flex-direction: row;
}

.tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 11rem;
  padding: 1rem;
  top: 50%;
  left: 40%;
  transform: translate(40%, -50%);
  background-color: var(--primaryLight);
  text-align: center;
  border-radius: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: pre-wrap;
}

a {
  color: var(--accent);
  text-decoration: none;
  cursor: pointer;
}

header {
  display: flex;
  align-items: center;
  padding: 2rem 0;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  header {
    display: flex;
    align-items: center;
    padding: .5rem 2rem 0 2rem;
    flex-direction: row;
  }
}

header img {
  height: 6rem;
}

@media screen and (min-width: 768px) {
  header img {
    margin-right: 2rem;
  }
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: var(--accent);
  padding: 0.5rem 0 0.5rem var(--header-padding);
  color: var(--white);
  -webkit-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  -moz-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
}

.top-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  background-color: var(--accent);
  padding: 0.5rem var(--header-padding);
  color: var(--white);
  -webkit-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  -moz-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
}

.top-bar a, .top-bar span, .top-menu > a, .top-menu span {
  color: var(--white);
  margin-left: 3rem;
  font-size: 0.8rem;
}

.download-hint {
  padding: 0.5rem 1rem;
  position: fixed;
  background-color: var(--accent);
  color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.download-hint button {
  border: 1px solid var(--white);
}

.top-bar .top-links {
  display: flex;
  width: 45vw;
}

.search {
  width: 45vw;
}


.top-menu > a {
  margin: 0;
}

.top-menu {
  justify-content: space-between;
  padding: 0.5rem 2.1rem;
}


.language-select {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.facility-select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.facility-select a {
  position: relative;
  margin-left: 1rem;
}

.language-select a {
  position: relative;
}

.top-bar-contextmenu {
  position: relative;
  height: 1rem;
  width: 1rem;
}

.top-bar-contextmenu img {
  max-height: 1rem;
}

.top-bar .search-results {
  position: absolute;
}

.search-results {
  z-index: 999;
  background-color: var(--white);
  border: 1px solid var(--grey);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .18);
  border-radius: 10px;
  margin: 1rem 0 0 0.5rem;
  padding: 1rem 1rem 1rem 1rem;
}

.search-results h4 {
  margin-bottom: 1rem;
}

.search-result {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--primaryDark);
  font-size: 1rem;
  cursor: pointer;
}

.search-result .actions{
  width: 10rem;
  margin-right: 1rem;
}

.search-result .actions button{
  width: 100%;
}

.search-result img {
  margin-right: 0.5rem;
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 100%;
}

.profile-image {
  height: 2rem;
  width: auto;
  border-radius: 100px;
  object-fit: cover;
  -webkit-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  -moz-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
}

.modal-profile-image {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 100px;
  object-fit: cover;
  -webkit-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  -moz-box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
  box-shadow: 9px 8px 6px -10px rgba(0,0,0,0.19);
}

.language-option {
  color: black;
  display: flex;
  align-items: center;
}

.stable-preview-div {
  height: 60px;
  width: 60px;
  min-width: 60px;
  max-height: 60px;
  border-radius: 10px;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.stable-preview-div img {
  width: 100%;
  height: auto;
  min-height: 60px;
  max-height: initial;
}


.align-left {
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 2rem;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  background-color: var(--accent);
}

@media screen and (min-width: 768px) {
  footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding: 3rem 2rem 1rem 2rem;
    background:url(/img/jutta-footer.svg) no-repeat;
    background-size: 100% 100%
  }
}

footer a {
  color: var(--white);
  text-decoration: none;
}

footer span {
  color: var(--white);
  margin-right: 0.5rem;
}

footer a {
  margin-left: 2rem;
}

/*Authentifizierung*/

.auth {
  margin: auto auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.createNew h4 {
  color: var(--accent);
  margin-bottom: 0.5rem;
}

.auth .createNew {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 100%;
  max-width: 30vw;
  height: auto;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: var(--white);
  align-items: stretch;
  margin: 0 var(--content-margin-mobile);
}

@media screen and (min-width: 768px) {
  .content {
    margin: 0 var(--content-margin);
  }
}

main {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--white);
  padding: 0;
  max-width: 100%;
}

#master {
  padding: 2rem;
}

nav {
  display: flex;
  flex-direction: column;
  width: 10vw;
  background-color: var(--white);
  padding: 1rem;
}

.links {
  display: flex;
  flex-direction: column;
}

.main-link {
  margin: 1rem 0;
}

.userlink {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.userlink h4 {
  margin-left: 1rem;
  color: var(--white);
}

.dropdown-triangle {
  height: 0.4rem;
  width: auto;
  margin-left: 0.5rem;
}

.dropdown-triangle-stable {
  position: absolute;
  top: 6px;
  margin-left: 0.5rem;
  height: 0.4rem;
  width: auto;
}

.userlink span {
  margin-left: 0.5rem;
  overflow-wrap: break-word;
}

.userlink img {
  cursor: pointer;
}

.sidebar {
  display: flex;
  flex-direction: column;
}

.sidebar .navLink{
  display: block;
  color: var(--accent);
  text-decoration: none;
  padding: 0.75rem;
}

.sidebar .subMenu {
  margin-top: 0.5rem;
  padding-bottom: 0.75rem;
}

.subMenu h5 {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-left: var(--accent) 3px solid;
}

.sidebar .navLinkSub {
  display: block;
  color: var(--accent);
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.75rem 0.75rem 0 1rem;
}

.sidebar .navLinkSub-active {
  text-decoration: underline;
}

.sidebar .active {
  border-left: 0.25rem solid var(--accent);
  background-color: var(--primaryLight);
  font-weight: bold;
}

.logo-top h3 {
  margin-left: 2rem;
}

.logo {
  cursor: pointer;
}

.logo, .logo-top .image {
  width: 7vw;
  height: auto;
  margin-bottom: 2rem;
  align-self: center;
}

.sign-up-container, .sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
  width: 50%;
  max-width: 45rem;
}

.sign-in-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sign-in-options-wrapper {
  width: 272px;
  padding: 0 1rem 0 0;
}


.link-text {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  text-align: center;
  color: var(--accent);
  cursor: pointer;
  margin-bottom: 1rem;
}

#registerwrapper {
  padding: 0 1rem 0 0;
}

#loginwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-in-options-wrapper button{
  width: 100%;
}

@media screen and (min-width: 768px) {
  .sign-up-container, .sign-in-container {
    padding: 0 2rem;
  }
}

.additional-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-in-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 50vw;
  padding: 1rem 0 0 0;
}

#sign-in-options-login {
  justify-content: space-evenly;
}

.sign-in-options button {
  width: 100%;
}

.sign-in-logo {
  max-width: 4rem;
  max-height: 4rem;
}

@media screen and (min-width: 768px) {
  .sign-in-logo {
    max-width: 15rem;
    max-height: 15rem;
  }
}

.mobile-content {
  padding: 7rem 1rem 1rem 1rem;
}

#mobile-options {
  margin-top: 4rem;
}

.sign-in-logo-wrapper{
  display: flex;
  justify-content: center;
}

.sign-in-social .social-btns {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.sign-in-social p {
  width: 20rem;
  margin-top: 1rem;
  font-size: 0.8rem;
  text-align: center;
  color: var(--error);
}

.icon-social, .icon-social:hover {
  background-color: var(--accent);
  padding: 0.5rem;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  cursor: pointer;
}

.storebuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.storebutton {
  max-height: 50px;
  margin-top: 1rem;
}

.storebuttonleft {
  margin-right: 1rem;
}

.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  text-align: center;
  color: var(--accent);
  margin: 0.5rem 1px 8px 1px;
}

#smartphoneapphint .hr-sect {
  margin: 1.5rem 1px 8px 1px;
  flex: 0
}

.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: var(--accent);
  height: 1px;
  width: 20px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.h-space {
  margin: 0 0.25rem
}

/* BUTTONS */

.date-selector {
  display: flex;
  flex-direction: row;
}

/* STABLES */

.stable-onboarding {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  width: 500px;
  justify-content: center;
  margin-top: 2rem;
  align-items: center;
  text-align: center;
}

.stable-onboarding img {
  height: 300px;
  margin-bottom: 2rem;
}

.stables-overview {
  display: flex;
  flex-wrap: wrap;
}

.stable-information {
  margin-bottom: 1rem;
}

.checkbox-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.checkbox-row label {
  margin-left: 1rem;
  width: auto;
  text-align: left;
}

.checkbox-row input {
  margin: 0
}

.modal-column {
  width: 100%;
}

.modal-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-row input {
  width: 100%;
}

.modal-row label {
  min-width: 7vw;
  max-width: 7vw;
}

.modal-save-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

h3 {
  margin: 1rem 0 0.5rem 0;
}

.stable-card {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.stable-card img {
  width: 200px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
}

.stable-body {
  padding: 0.25rem;
  max-width: 192px;
}

.stable-card-add {
  width: 200px;
  height: 120px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent);
}

.stable-card-add h1 {
  color: white;
  font-size: 5rem;
}

.stable-about {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stable-about-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  max-width: 600px;
  min-width:300px;
  margin-top: 1rem;
}

.stable-about-edit {
  top:0;
  right:0;
  position: absolute;
}

.stable-about-edit button{
  width: 100%;
}


.stable-about p {
  white-space: pre;
  margin: 0 0 0 0.25rem;
}

.stable-about-grey {
  color: var(--grey);
}

.stable-contact-person-edit {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.stable-contact-person-edit img{
  border-radius: 10px;
}

.stable-contact-person-edit .primary-button{
  margin-top: 2rem;
  width:22rem
}

.stable-contact-person {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stable-contact-person-info {
  display: flex;
  flex-direction: column;
}

.stable-contact-role {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}

.stable-contact-role h4 {
  margin-left: 0.25rem;
}


/* TODO The Carousel component does not seem to resize images for the smallest site */
.stable-image-carousel {
  min-height: 400px;
  width: 600px;
  margin: 1rem 0 2rem 0;
}

.stable-about-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 600px;

}

.stable-image-carousel > div {
  min-height: 400px;
}
.stable-image-carousel > div > div:first-child {
  min-height: 400px;
}

.stable-image-carousel-image-wrapper {
  height:100%;
  width:100%;
  display: flex;
}

.stable-image-carousel-image-wrapper img {
  margin:auto;
  height: 400px;
  width: 600px;
  object-fit: cover;
}

.carousel-dots {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: var(--accent);
  margin: 7px 5px;
  transition-duration: 1000ms;
  z-index: 1004;
  cursor: pointer;
}

.carousel-dots-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: center;
  z-index: 1003;
}

.carousel-button {
  top: 45%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  bottom: 0;
  font: 16px/30px sans-serif;
  color: var(--accent);
}

.carousel-button img {
  filter: drop-shadow(0 0 10px rgba(0,0,0,0.2)) opacity(50%);
}

.carousel-button img:hover {
 filter: drop-shadow(0 0 10px rgba(0,0,0,0.4)) opacity(100%);
}

.left {
  left: 2px;
}

.left img {
  transform: rotate(180deg);
}

.right {
  right: 25px;
}

.carousel-button-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  bottom: 0;
  text-align: center;
}



.stable-body a, .stable-body h4 {
  color: var(--primaryDark);
}

.stable-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
}

.stable-header h1 {
  margin-right: 1rem;
}

.stable-header a {
  position: relative;
}

.stable-contact {
  margin-top: 0.5rem;
}

.join-stable-option {
  text-align: center;
  margin-top: 1rem;
}

.join-stable-option .action-wrapper{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.join-stable-option .action-wrapper button{
  margin: 0.5rem 1rem;
}


.location {
  color: var(--grey);
}

/* FACILITIES */

.facilities{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.facility {
  margin:1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.facility-card-img {
  width: 3rem;
  height: 3rem;
}

.stable-contact img {
  width: 3rem;
  height: 3rem;
}

.facility-card-img, .stable-contact img {
  margin-right: 1rem;
  border-radius: 10px;
  object-fit: cover;
}

.facility-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.facility-header img {
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
  border-radius: 10px;
  object-fit: cover;
}

.create-edit-facility-header{
  margin: 0.5rem 0;
}

/* MODAL */

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 0.75rem;
  color: var(--primaryDark);
  cursor: pointer;
}

.modal h3 {
  margin-bottom: 1rem;
}

.modal textarea {
  width: 95%;
}

.modal .modal-content {
  background-color: var(--white);
  padding: 1rem 2.5rem;
  min-width: 500px;
  max-width: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  position: relative;
}

.modal .switch-stable-content {
  background-color: var(--white);
  padding: 1rem 5rem;
  min-width: 500px;
  max-width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
}

.modal .are-you-sure{
  min-width: 300px;
  max-width: 25vw;
}


.modal-header{
  margin: 0.5rem 0 1rem 0;
}

.modal-stable-entry {
  display: flex;
  margin: 0.25rem 0;
  justify-content: flex-start;
  align-items: center;
}

.modal-stable-entry img {
  width:60px;
  height: 60px;
  margin-right: 0.75rem;
  border-radius: 10px;
}

.modal-stable-entry a {
  text-decoration: none;
  color: var(--accent);
  cursor: pointer;
}

.modal-actions-wrapper{
  margin: 1rem 0 0 0;
}

.post-list {
  display: flex;
  flex-direction: column;
  width: 100%
}

/* RENDERED POST */
.post-toolbar-entry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primaryLight);
  padding: 0.25rem;
  margin-right: 1rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.post-toolbar-entry-text, .comment-toolbar-entry-text {
  margin-right: 0.5rem;
}

.post-container {
  max-width: 500px;
  border: 1px solid var(--primaryLight);
  border-radius: 10px;
  margin-bottom: 1rem;
  position: relative;
}

.post-container > .overlay{
  background-color: var(--primaryLightTransparent);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.stable-selected-images {
  max-width: 30rem;
}

.stable-selected-image-wrapper {
  height: 200px;
  margin-right: 1rem;
}

.stable-selected-image-wrapper img{
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.stable-new-image-wrapper {
  width: 150px;
  height: 198px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid var(--primaryLight);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.stable-new-image-text {
  color: var(--primaryDark);
  white-space:pre-wrap;
}


.post-selected-images-wrapper, .comment-selected-images-wrapper {
  height: 100px;
  margin-right: 1rem;
}

.post-selected-images-wrapper img, .comment-selected-images-wrapper img{
  width: auto;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.post-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.post-tile-date, .comment-tile-date {
  font-size: 0.8rem;
  color: var(--grey)
}

.post-tile img {
  height: 3rem;
  width: 3rem;
  border-radius: 100px;
  cursor: pointer;
  object-fit: cover;
}

.post-tile-name {
  cursor: pointer;
}

.post-content-text {
  word-break: break-all;
  margin: 0.5rem 1.5rem 0 1.5rem;
}

.post-images {
  margin: 1.5rem 0.5rem 0 0.5rem;
  display: flex;
  flex-direction: column;
}

.post-images .thumbs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.thumbs a {
  height: auto;
}

.post-images .two-tile {
  width: 240px;
  height: 210px;
  object-fit: cover;
}
.post-images .three-tile, .post-images .odd-image img {
  width: 156px;
  height: 156px;
  object-fit: cover;
}

.odd-image {
  display: flex;
  position: relative;
}

.odd-image .overlay{
  background-color: var(--primaryDarkTransparent);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--white);
}

.post-images .main-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}


.post-reaction, .comment-reaction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid var(--primaryLight);
  border-bottom: 1px solid var(--primaryLight);
}

.post-reaction .btn, .comment-reaction .btn{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.post-reaction .btn:hover, .comment-reaction .btn:hover {
  background-color: var(--primaryLight);
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
}

.edit-stable-page input, .edit-stable-page textarea, .edit-stable > div{
  width: 30rem;
  max-width: 100%;
}

.edit-stable-page button, .edit-facility-page button {
  width: 10rem;
}

.edit-stable-page > h1{
  margin: 0.5rem 0;
}

.create-stable-header{
  margin-bottom: 1rem;
}

.create-stable-header h2{
  margin: 0.5rem 0;
}

.create-stable-permission{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-stable-permission input{
  width:  auto;
  margin-right: 1rem;
}

.create-stable-subheader{
  margin: 2rem 0 0.5rem 0;
  font-size: 1.25rem;
}

@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 576px) {

  .icon-spacing {
    margin: 0 auto;
  }

  .max-width-50 {
    max-width: 100%;
  }
}

.leave {
  background-color: var(--primaryDark);
  width: 15%;
}

/* USERS */

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.list-tile {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.list-tile-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list-tile-options div, .list-tile-options button {
  margin-right: 1rem;
}

.user, .horses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.user-avatar{
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin-right: 1rem;
}

.horse-avatar{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  margin-right: 1rem;
}

#repetition-input {
  height: 44px;
}

.horses {
  margin-left: 0.8rem;
}

.horses div {
  margin-bottom: 0.5rem;
}

/* RENDERED COMMENTS */

.comment-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.comment-tile img {
  height: 2rem;
  width: 2rem;
  border-radius: 100px;
  margin-right: 1rem;
  cursor: pointer;
  object-fit: cover;
}

.comment-tile-name {
  font-size: 0.9rem;
  cursor: pointer;
}

.comment-content-text {
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-size: 0.9rem;
}

.comment-container {
  max-width: 450px;
  border: 1px solid var(--primaryLight);
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 4px;
}

.comment-section {
  max-height: 500px;
  overflow: auto;
}

.comments-load-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1.5rem 1rem 1.5rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.comment-image-wrapper {
  margin: 0.5rem;
}


.comment-image-wrapper img {
  width: 156px;
  height: 156px;
  object-fit: cover;
}

.create-comment {
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.comment-toolbar-entry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primaryLight);
  padding: 0.3rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.comment-toolbar-entry-icon, .post-toolbar-entry-icon {
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-toolbar-entry-icon img, .post-toolbar-entry-icon img {
  width: 100%;
}

.contextmenu {
  position: relative;
  margin-left: auto;
  cursor: pointer;
}

.contextmenu img {
  width: 1rem;
  max-height: 1rem;
}

.drop_menu {
  right: 0;
  bottom: 20px;
  position: absolute;
  border: 1px solid var(--primaryLight);
  border-radius: 4px;
  background-color: var(--white);
  padding: 0.25rem 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .18);
  z-index: 1001;
}

.drop_menu a {
  margin: 0;
}

.down {
  bottom: 0;
  top: 20px;
  min-width: 10rem;
  max-height: 20rem;
  height: fit-content;          
}

@supports (-moz-appearance:none) {
  .down { display:table }
}

.disabled-option {
  pointer-events: none;
  cursor: default !important;
}

.disabled-option div {
  cursor: default !important;
}

.disabled-option .language-option {
  background-color: lightgrey;
}

.option {
  z-index: 1002;
}

.drop_menu .option {
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  border-radius: 4px;
}

.drop_menu .option:hover {
  background-color:var(--primaryLight);
}

.card-column .header {
  background-color: var(--primaryLight);
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-column .body {
  padding: 1rem;
}

.mobile {
  display: inherit;
}

@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
}

.desktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .desktop {
    display: inherit;
  }
}

.deletable-img {
  height: 15rem;
  width: 15rem;
  border-radius: 5px;
  object-fit: cover;
}

.thumb {
  height: 5rem;
  width: 5rem;
  border-radius: 5px;
  object-fit: cover;
}

.thumbs-wrapper {
  height: 5rem;
  width: 5rem;
  margin-top: 1rem;
}

.pdf-window{
  width: 100%;
  height: 100%;
  min-height: 800px;
}

.pdf-window > iframe{
   width: 100%;
   height: 100%;
 }

#delete-facility a{
  color: darkred;
}

#delete-facility .settings-icon {
  background-color: darkred;
}

.circle {
  border-radius: 100%;
  /*background-color: red;*/
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
}