.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.width-50 {
  min-width: 12rem;
}

.width-75 {
  min-width: 15rem;
}

.width-100 {
  width: 100%;
}

.m-auto {
  margin: auto;
}