.loading-indicator-post{
    background-color: var(--primaryLight);
    margin-right: 1rem;
    height:100px;
    min-width: 100px;
    border-radius: 10px;
}

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.info-div {
    background-color: var(--accent);
    padding: 1rem;
    border-radius: 1rem;
    color: var(--white);
    position: relative;
    display:flex;
    align-items: center;
}

.info-div h1 {
    color: var(--white);
    margin-left: 1rem;
}

.info-div small {
    padding-left: 1rem;
    padding-right: 1rem;
}

.loading-indicator div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-radius: 50%;
    animation: loading-indicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--accent) transparent transparent transparent;
}

.loading-indicator.loading-indicator-small div {
    width: 32px;
    height: 32px;
    border: 4px solid;
    border-color: var(--accent) transparent transparent transparent;
}

.loading-indicator div:nth-child(1) {
    animation-delay: -0.45s;
}
.loading-indicator div:nth-child(2) {
    animation-delay: -0.3s;
}
.loading-indicator div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loading-indicator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dot {
    height: 5px;
    width: 5px;
    background-color: var(--darkGrey);
    border-radius: 50%;
    display: inline-block;
    margin: 0 0.5rem;
}

.breadcrumbs {
    display: flex;
    width: 100%;
}

.breadcrumbs .crumb {
    margin: 0 .5rem
}

.breadcrumbs p {
    margin: 0;
}

.clickable {
    cursor: pointer;
}

