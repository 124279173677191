.card {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--grey);
  margin: 1rem 0 1rem 0;
}

.card-header, .facility-card-header {
  background-color: var(--primaryLight);
  border-right: 1px solid var(--grey);
  padding: 1rem;
  width: 15rem;
}

.facility-card-header {
  display: flex;
  flex-direction: column;
}

.card-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30rem;
}

.setting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.setting:last-child {
  margin-bottom: 0;
}

.settings-icon {
  background-color: var(--accent);
  padding: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.settings-icon img {
  width: 1.5rem;
}

.card-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
}

.card-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-bottom: 0.5rem;
  width: 100%;
}

.align-items-center {
  align-items: center;
}

.card-row input, label {
  width: 90%
}

.card-row p {
  width: 8vw;
}

#openingHourBody label {
  width: 8vw;
}
#openingHourBody input {
  width: 30%;
}

.card-row p {
  font-size: .8rem;
  margin: 0;
}

.time-row {
  display: flex;
}

.time-row div {
  margin-right: 2rem;
}