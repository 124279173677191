.user-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.user-page .overlay {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 100%;
  cursor: pointer;
}

.user-page .user-image {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 2rem;
}

.user-page .container {
  position: relative;
}

.user-page .overlay {
  background-color: black;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-page .overlay span {
  color: var(--white);
  font-size: 1rem;
}

.user-page .container:hover .user-image {
  opacity: 0.3;
}

.user-page .container:hover .overlay {
  opacity: 1;
}

.user-page .main-data {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-information {
  margin-left: 2rem;
}

.statistic {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.statistic div {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin: 1rem 1rem 1rem 0;
  font-size: 0.8rem;
}

.statistic h4 {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.user-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* USER */

.user-edit-page  .primary-button,.user-edit-page  .warning-button {
  margin-top: 2rem;
  width:22rem
}

.user-information-header {
  font-size: 1.5rem;
}

.caption {
  color: var(--darkGrey);
  padding-bottom: 4px;
  padding-top: 4px;
}

.edit-button-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.input-description {
  width: 30rem;
  max-width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}
